import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./Banner.css";

const Banner = () => {
  return (
    <article className="banner-style">
      <Container fluid="md">
        <Row>
          <Col
            md={8}
            className="my-auto mx-auto px-2  "
            style={{
              textAlign: "start",
            }}
          >
            <h1 className="pb-3 fw-bolder text-white">
              Rozweb Solution Limited: 
              Your Web App Experts
            </h1>

            {/* Grouped the h5 elements for better readability */}
            <div> 
              <h5 className="pb-3">
                🚀 Web development services: Restaurants, Personal, Blog, Educational, Corporate, Charity, News, E-commerce.
              </h5>
              <h5 className="pb-3">
                ✅ Why Choose Us: Proven expertise, client-centric approach, and a commitment to exceeding expectations.
              </h5>
              <h5 className="pb-3">
                🤝 Company Benefits: Collaborative culture, continuous learning opportunities, and competitive compensation.
              </h5>
              <h5 className="pb-3">
                💻 Our Tech Stack: We leverage the power of React, MongoDB, Express, and Node, Redux, Firebase, Typescript, Mongoose, Redux, Next.js, GraphQL, PostgreSQL, AWS, Docker, Unit Testing, and many more cutting-edge technologies to build robust and scalable web applications.
              </h5>
              <h5 className="pb-3">
                🎯 Our Mission: To empower businesses with cutting-edge technology solutions that drive success.
              </h5>
            </div>

          </Col>
          <Col md={4} className="my-auto text-center">
            <Button variant="primary" size="lg" className="fw-bold">
              <a
                className="text-decoration-none text-white"
                href="/"
              >
                Contact with us 🚀
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Banner;