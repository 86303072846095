import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import './About.css'; 

import saiful from '../../images/Saiful-Islam-Selju.png'
import mosheur from '../../images/mosheur.jpg'; 

const About = () => {
  return (
    <Container className="about-section py-5 px-4 ">
       <Row className="justify-content-center align-items-center">
        <Col md={6} lg={5} className="mb-4 mb-lg-0"> 
          <Image src={saiful} alt="Saiful Islam Selju" className="rounded-circle img-fluid" />
        </Col>
        <Col md={6} lg={7}>
          <h2 className="mb-3">Saiful Islam Selju</h2>
          <h4 className="text-primary mb-4">Co-founder & Chief Executive Officer (CEO)</h4>
          <p>
            {/* Add details about Saiful's background, experience, vision, etc. */}
            Saiful is a passionate entrepreneur with a proven track record in leading and scaling businesses. 
            He has a keen eye for identifying market opportunities and building innovative solutions. 
            As CEO, Saiful drives the company's strategic direction and oversees its overall growth.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center align-items-center mt-5 flex-row-reverse"> 
        <Col md={6} lg={5} className="mb-4 mb-lg-0">
          <Image src={mosheur} alt="Mosheur Rahman" className="rounded-circle img-fluid" />
        </Col>
        <Col md={6} lg={7}>
          <h2 className="mb-3">Mosheur Rahman</h2>
          <h4 className="text-primary mb-4">Co-founder & Chief Technology Officer (CTO)</h4>
          <p>
            Mosheur is a highly skilled technologist with expertise in various domains. He honed his skills 
            working with leading software companies in Bangladesh and Australia, including Progeny 
            Technologies Ltd. and Proxmed. This diverse experience has given him a unique perspective 
            on developing scalable and reliable systems. He leads the company's technological vision and 
            oversees the development of cutting-edge solutions. Mosheur is passionate about building 
            systems that deliver exceptional user experiences. 
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;