// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBw4nmt33Ixgva1L6LvhGYTN_GajS0nUfI",
  authDomain: "rozweb-uk.firebaseapp.com",
  projectId: "rozweb-uk",
  storageBucket: "rozweb-uk.firebasestorage.app",
  messagingSenderId: "595901924746",
  appId: "1:595901924746:web:57d09f0ce1e15ac1b43473",
  measurementId: "G-JX57SY80CN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;
