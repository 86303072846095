import { signOut } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import auth from "../../../firebase.init";
import logo from "../../../images/logo.png";
import CustomLink from "../../CustomLink/CustomLink";
import { HiMenuAlt3 } from "react-icons/hi"; // Import the desired icon component
import "./Header.css";

const Header = () => {
  const [user] = useAuthState(auth);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const handleSignOut = () => {
    signOut(auth);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        // bg="#ffff"
        className={`p-0 sticky-top ${!visible ? "hide" : ""}`}
      >
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={scrollToTop}>
            <img
              alt="Basic programmer"
              src={logo}
              width="60"
              height="60"
              className="d-inline-block align-center nav-logo border rounded-circle"
            />{" "}
            <b style={{color: "#00FFFF"}}>
              <span style={{color: "blue"}}>ROZ</span>WEB
            </b>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="border-0 !text-while {
              
            }"
          >
            <HiMenuAlt3 className="text-white" />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="text-center px-5 mx-5"
          >
            <Nav className="ms-auto">
              <Nav.Link as={CustomLink} to="/home" onClick={scrollToTop}>
                Home
              </Nav.Link>
              <Nav.Link as={CustomLink} to="/services" onClick={scrollToTop}>
                Services
              </Nav.Link>
              <Nav.Link as={CustomLink} to="/contact" onClick={scrollToTop}>
                Contact 
              </Nav.Link>
              <Nav.Link as={CustomLink} to="/blogs" onClick={scrollToTop}>
                Blogs
              </Nav.Link>
              <Nav.Link as={CustomLink} to="/about" onClick={scrollToTop}>
                About
              </Nav.Link>
              <Nav.Link>
                {user ? (
                  <Button
                    onClick={handleSignOut}
                    variant="link"
                    className="text-decoration-none"
                  >
                    <b>Log Out</b>
                  </Button>
                ) : (
                  <Nav.Link className="my-auto" as={CustomLink} to="/login">
                    Login
                  </Nav.Link>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
