import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Image, Container } from "react-bootstrap";
import web from "../../../images/freeweb.jpg";
import "./Discount.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Discount = () => {
  // Set the target date
  const targetDate = new Date("2025-01-1");

  // Calculate the remaining time
  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <Container className="my-4 ">
      <Card className="p-4 border-2 gradient-background">
        <Row className="align-items-center">
          <Col lg={6} className="image-container">
            <Image src={web} alt="Album" fluid className="styled-image" />
          </Col>
          <Col lg={6}>
            <Card.Body>
              <Card.Title className="text-center">
                <h2 className="title">
                  Launch Your Website Now - 10% discounts Development
                </h2>
              </Card.Title>
              <Card.Text className="text-center">
                <h3 className="subtitle">
                  Don't Miss Out! Get a Professional Website Built Absolutely
                  10% discounts.
                </h3>
              </Card.Text>
              <div className="text-center my-4">
                {/* <Button variant="success" className="styled-button"> */}
                  <a
                    className="text-decoration-none"
                    href="https://wa.me/447403327077"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className=" fa-3x bg-bg-transparent border-0 text-white bg-success p-2 rounded-3"
                    />{" "}
                  </a>
                {/* </Button> */}
              </div>
              <div className="text-center">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} className="countdown-col">
                    <div className="countdown-timer">{days}</div>
                    <div className="countdown-label">days</div>
                  </Col>
                  <Col xs={3} sm={2} className="countdown-col">
                    <div className="countdown-timer">{hours}</div>
                    <div className="countdown-label">hours</div>
                  </Col>
                  <Col xs={3} sm={2} className="countdown-col">
                    <div className="countdown-timer">{minutes}</div>
                    <div className="countdown-label">min</div>
                  </Col>
                  <Col xs={3} sm={2} className="countdown-col">
                    <div className="countdown-timer">{seconds}</div>
                    <div className="countdown-label">sec</div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Discount;
