import React from "react";
import { Container, Row } from "react-bootstrap";

const CheckOut = () => {
  return (
    <Container fluid="md">
      <Row className="justify-content-md-center">
        <div
          style={{ marginBottom: "200px" }}
          className="text-center mt-5 text-black"
        >
          <h2 className="mb-3">You are successful.</h2>
          <h5>
            Please contact us to discuss your project requirements. We can't
            consider or proceed without further communication to understand your
            needs.
          </h5>
          <h5 className="mb-3">
            <a
              className="fw-bold"
              href="https://wa.me/447403327077"
            >
              Please contact with us
            </a>
          </h5>
        </div>
      </Row>
    </Container>
  );
};
export default CheckOut;
