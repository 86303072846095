import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  useCreateUserWithEmailAndPassword,
  useUpdateProfile,
} from "react-firebase-hooks/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import auth from "../../../firebase.init";
import Loading from "../../Shared/Loading/Loading";
import SocialLogin from "../SocialLogin/SocialLogin";

const Register = () => {
  const [agree, setAgree] = useState(false);
  let errorElement;
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth, { sendEmailVerification: true });

  const [updateProfile, updating, updateError] = useUpdateProfile(auth);

  if (error || updateError) {
    errorElement = <p className="text-danger">Error: {error?.message}</p>;
  }

  const navigate = useNavigate();

  const navigateLogin = () => {
    navigate("/login");
  };

  const location = useLocation();
  let from = location.state?.from?.pathname || "/";
  if (user) {
    navigate(from, { replace: true });
    console.log(user);
  }

  if (loading || updating) {
    return <Loading></Loading>;
  }

  const handleRegister = async (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const password = event.target.password.value;
    await createUserWithEmailAndPassword(email, password);
    await updateProfile({ displayName: name });
    alert("Updated profile");
    console.log("Update profile");
    navigate("/home");
  };

  return (
    <Container>
      <Row className="justify-content-md-center mb-3 text-white text-white">
        <Col
          xs="12"
          lg="4"
          className="mt-3 mx-auto rounded-top px-5 pt-4 border border-bottom-0 border-1 shadow-lg"
          style={{
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 className="text-center my-3 text-white">Please Register</h2>
          <Form onSubmit={handleRegister} className="mb-3 text-white">
            <Form.Group className="mb-3 text-white" controlId="formBasicText">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Your Name"
                required
                style={{
                  borderRadius: "5px",
                  boxShadow: "inset 0px 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-white" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Your Email"
                required
                style={{
                  borderRadius: "5px",
                  boxShadow: "inset 0px 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-white" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                required
                style={{
                  borderRadius: "5px",
                  boxShadow: "inset 0px 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="w-100"
              style={{ borderRadius: "5px" }}
            >
              Register
            </Button>
          </Form>
          {errorElement}
          <p>
            Already have an account?
            <Link
              to="/login"
              className="text-primary pe-auto text-decoration-none ms-1"
              onClick={navigateLogin}
            >
              Please Login
            </Link>
          </p>
        </Col>
        <SocialLogin></SocialLogin>
      </Row>
    </Container>
  );
};

export default Register;
