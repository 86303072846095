import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./ServiceDetail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const ServiceDetail = () => {
  const [user] = useAuthState(auth);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { serviceId } = useParams();
  const [Project, setProject] = useState([]);
  useEffect(() => {
    fetch("/services.json")
      .then((res) => res.json())
      .then((data) => {
        const FindProject = data.find((d) => d.id === serviceId);
        setProject(FindProject);
      });

    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, [serviceId]);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log(data);
    toast.success("Course added successfully");
    navigate(`/checkout/${serviceId}`);
  };

  return (
    <Container fluid="md">
      <Row className="justify-content-md-center mt-5 text-white">
        <Col lg="8" className="text-center mb-5">
          <img className="w-100" src={Project?.img} alt="" />
          <div style={{ fontWeight: "bold", textAlign: "justify" }}>
            <h2 className="pt-3 fw-bold">{Project?.name}</h2>

            <h3 style={{ fontWeight: "bold", textAlign: "start" }}>
              <strong>Price Range: </strong> {Project?.price} (depending on the
              complexity of features, and level of customization)
            </h3>
            <p>
              <strong>Description: </strong>
              {Project?.description}
            </p>
            <p>
              <strong>Requirements: </strong>
              {Project?.requirements}
            </p>
            <p>
              <strong>Technologies: </strong>
              {Project?.technologies}
            </p>
          </div>
        </Col>
        <Col lg="4" className="text-center mb-5">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div>
              <input
                type="text"
                placeholder="Your Name"
                value={user?.displayName}
                className="checkOut-input"
                {...register("customerName", {
                  required: {
                    value: true,
                    message: "Name is Required",
                  },
                })}
              />
            </div>

            <div>
              <input
                type="email"
                placeholder="Your Email"
                value={user?.email}
                className="checkOut-input"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email is Required",
                  },
                })}
              />
              <label className="label">
                {errors.email?.type === "required" && (
                  <span className="label-text-alt text-red-500">
                    {errors.email.message}
                  </span>
                )}
              </label>
            </div>

            <div>
              <input
                type="number"
                placeholder="Your Phone Number"
                className="checkOut-input"
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Phone is Required",
                  },
                })}
              />
              <label className="label">
                {errors.phone?.type === "required" && (
                  <span className="label-text-alt text-red-500">
                    {errors.phone.message}
                  </span>
                )}
              </label>
            </div>

            <div>
              <textarea
                type="textarea"
                placeholder="Your Address"
                className="checkOut-input"
                {...register("address", {
                  required: {
                    value: true,
                    message: "Address is Required",
                  },
                })}
              />
              <label className="label">
                {errors.address?.type === "required" && (
                  <span className="label-text-alt text-red-500">
                    {errors.address.message}
                  </span>
                )}
              </label>
            </div>

            <div>
              {user ? (
                <input
                  className="checkOut-btn"
                  type="submit"
                  value="Check out"
                />
              ) : (
                <p className="checkOut-btn">
                  {" "}
                  <Link className="text-decoration-none text-white" to="/login">
                    Please Login First
                  </Link>
                </p>
              )}
            </div>
          </form>

          <Row className="justify-content-md-center">
            <Col className="mx-auto text-center p-2 mb-3">
              <div className="d-flex align-items-center">
                <div style={{ height: "2px" }} className="bg-black w-50"></div>
                <p className="mt-2 px-2 fw-bold">or</p>
                <div style={{ height: "2px" }} className="bg-black w-50"></div>
              </div>
              <a
                className="text-decoration-none social-icon"
                href="/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="p-3 text-white fa-2x text-white"
                />
              </a>
              <a
                className="text-decoration-none"
                href="/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="p-3 text-white fa-2x text-white"
                />
              </a>
              <a
                className="text-decoration-none"
                href="https://wa.me/447403327077"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="p-3 text-white fa-2x text-white"
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default ServiceDetail;
