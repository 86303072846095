import React from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container>
      <Row className="justify-content-center mt-5 mb-5">
        <Col md={6}>
          <h2 className="text-center mb-4 text-white">Contact Us</h2>
          <Form >
            <Form.Group className="mb-3 text-white" controlId="formBasicName">
              <Form.Label>Your Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>

            <Form.Group className="mb-3 text-white" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group className="mb-3 text-white" controlId="formBasicMessage">
              <Form.Label>Your Message</Form.Label>
              <Form.Control as="textarea" rows={4} placeholder="Enter your message" />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Send Message
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;