import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import "./Reviews.css";
import { Rating } from "@smastrom/react-rating";

import "@smastrom/react-rating/style.css";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    fetch("reviews.json")
      .then((res) => res.json())
      .then((data) => setReviews(data));
  }, []);
  return (
    <div>
      <Container fluid className="reviews-style mt-5 mx-auto">
        <Row>
          <Col md={6} className="my-auto mx-auto pt-3 text-center">
            <h1 className="mb-4 fw-bold">
            What Our Clients Say 😍
            </h1>
         

            <Row className="justify-content-md-center bg-opacity-75">
              <Carousel fade>
                {reviews.map((review, index) => (
                  <Carousel.Item key={index}>
                    <Rating
                      className="mx-auto"
                      style={{ maxWidth: 180 }}
                      value={review.rating}
                      readOnly
                    />
                    <h3>{review.userName}</h3>
                    <img
                      className="d-block mx-auto rounded-2 mb-2"
                      style={{
                        width: "200px",
                        height: "200px",
                        // objectFit: "cover",
                      }}
                      src={review.img}
                      alt={review.userName}
                    />

                    <p className="pb-4 fw-bold">{review.comment}</p>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reviews;
