import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Servise.css";

const Service = ({ service, index }) => {
  const { id, name, img, description } = service;
  const navigate = useNavigate();
  const navigateToServiceDetail = (id) => {
    navigate(`/service/${id}`);
  };

  // Define an array of gradient color combinations
  const gradientColors = [
    // "linear-gradient(to right,  #142247, #E10FA8, #5271FF)",
    // "linear-gradient(135deg, #142247, #0F0B16)",
    "linear-gradient(to right, #131836, #00103D)",
    "linear-gradient(to left, #010101, #00103D)",
  ];

  // Choose a gradient based on the index
  const backgroundGradient = gradientColors[index % gradientColors.length];

  return (
    <Col lg="10" className="text-center mb-5 ">
      <div
        className="service-container p-2 text-white"
        style={{
          background: backgroundGradient, // Apply the gradient
          color: "#0D161B",
        }}
      >
        <Row className="align-items-center justify-content-center">
          {index % 2 === 0 ? (
            <>
              <Col lg="6">
                <img className="w-100 rounded service-image" src={img} alt="" />
              </Col>
              <Col lg="6" className="position-relative">
                <div className="text-start">
                  <h1 style={{ fontWeight: "bold" }} className="pt-2">
                    {name}
                  </h1>

                  <p style={{ fontWeight: "bold" }}>
                    {description.slice(0, 180)}
                  </p>

                  <div className="py-2">
                    <Button
                      onClick={() => navigateToServiceDetail(id)}
                      variant="primary"
                      size="lg"
                      style={{
                        fontSize: "1.01rem",
                        textAlign: "justify",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      Explore {name} 🚀
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col
                lg="6"
                md={{ order: "first" }}
                xs={{ order: "last" }}
                className="position-relative"
              >
                <div className="text-start">
                  <h1 style={{ fontWeight: "bold" }} className="pt-2">
                    {name}
                  </h1>

                  <p style={{ fontWeight: "bold" }}>
                    {description.slice(0, 480)}
                  </p>
                  <div className="py-2">
                    <Button
                      onClick={() => navigateToServiceDetail(id)}
                      variant="primary"
                      size="lg"
                      style={{
                        fontSize: "1.01rem",
                        textAlign: "justify",
                        fontWeight: "bold",
                      }}
                    >
                      Explore {name} 🚀
                    </Button>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md={{ order: "last" }}
                xs={{ order: "first" }}
                className="d-flex justify-content-center"
              >
                <img className="w-100 rounded service-image" src={img} alt="" />
              </Col>
            </>
          )}
        </Row>
      </div>
    </Col>
  );
};

export default Service;